import { useEffect, useState } from "react";
import AuthService from "./services/Auth";
import BookingProvider from "./context/BookingContext";
import SelectionView from "./components/SelectionView";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [token, setToken] = useState("");

  const login = async () => {
    const token = await AuthService.LoginAnonymous();
    setToken(token);
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <BookingProvider>
      {token && (
        <Router>
          <Routes>
            <Route path="/" element={<SelectionView />} />
            <Route path="meet/:userName" element={<SelectionView />} />
            <Route path="*" element={<SelectionView />} />
          </Routes>
        </Router>
      )}
    </BookingProvider>
  );
}

export default App;
