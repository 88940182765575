import api from "../data/api";

import { getMonthStartEndDates, dateFormatWordPress } from "../utils/dates";

class TaskService {
  static async getTasks(userId, fromDate, toDate) {
    try {
      const res = api.get(
        `${process.env.REACT_APP_HUB_URL}tasks?author=${userId}&from_date=${fromDate}&to_date=${toDate}`
      );
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getTasksForUserForMonth(userId, month) {
    const dates = getMonthStartEndDates(month);
    
    const query = `${process.env.REACT_APP_HUB_URL}tasks?author=${userId}&from_date=${dates.first}&to_date=${dates.last}`;
    try {
      const res = await api.get(query);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getTask(id) {
    try {
      const res = await api.get(`${process.env.REACT_APP_HUB_URL}tasks/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async createTask(
    title,
    authorId,
    startDate,
    endDate,
    projectId,
    notes
  ) {
    const start = dateFormatWordPress(startDate);
    const end = dateFormatWordPress(endDate);

    try {
      const newTask = new FormData();
      newTask.append("title", title);
      newTask.append("author", authorId);
      newTask.append("start", start);
      newTask.append("end", end);
      newTask.append("project", projectId);
      newTask.append("notes", notes);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const res = await api.post(
        `${process.env.REACT_APP_HUB_URL}tasks`,
        newTask,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default TaskService;
