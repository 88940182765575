import api from "../data/api";

const activeUser = [
  {
    email: "jason@fl1.digital",
    avatar: "https://hub.fl1digital.com/wp-content/uploads/2018/03/jas.jpg",
  },
  {
    email: "emily@fl1.digital",
    avatar:
      "https://hub.fl1digital.com/wp-content/uploads/2022/06/1-600x600.png",
  },
  {
    email: "suzy@fl1.digital",
    avatar:
      "https://hub.fl1digital.com/wp-content/uploads/2018/05/IMG_6264-3-600x600.jpg",
  },
  {
    email: "niall@fl1.digital",
    avatar: "https://hub.fl1digital.com/wp-content/uploads/2018/03/niall.jpg",
  },
];

class UserService {
  static async getUser(userId) {
    const filter = `?user_id=${userId}`;
    return this.getAllUsers(filter);
  }
  static async getAllUsers(filter) {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_HUB_URL}users?${filter}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getActiveUsers() {
    const users = await this.getAllUsers("");

    if (!users) {
      return [];
    }

    const activeUsers = [];

    users.map((user) => {
      const u = activeUser.find(
        (active) => active.email === user.data.user_email
      );
      if (u) {
        const newUser = {
          ...user,
          data: {
            ...user.data,
            avatar: u.avatar,
          },
        };
        activeUsers.push(newUser);
      }
    });
    return activeUsers;
  }
}

export default UserService;
